<template>
  <el-drawer
    size="75%"
    :visible.sync="show"
    :before-close="beforeClose"
  >
    <div style="display:flex;justify-content:flex-start;align-items:center" slot='title'>
      <div class="line-overflow" style='text-align:right' slot="title">
        {{ getTitle() }}
      </div>
      <div>
        公众号列表
      </div>
    </div>

    <div class="main-container">
      <div class="operation ">
        <el-button @click="refreshData">刷新数据</el-button>
        <el-button type="primary" @click="putChannel" style="margin-right:10px"
          >上传公众号</el-button
        >
        <el-button
          type="danger"
          plain
          @click="delSelect"
          :disabled="selectionList.length === 0"
          style="margin-right:10px"
          >批量删除</el-button
        >
        <el-select
          v-model="accountChannelId"
          placeholder="请选择公众号"
          filterable
          clearable
          @clear='clearAccountChannelId'
          style="width:200px;margin-right:10px"
        >
          <el-option
            :label="item.channelName"
            :value="item.id"
            v-for="item in officialList"
            :key="item.id"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
        <el-select
          v-model="isFailed"
          placeholder="状态"
          style="margin-right:10px;width:100px"
        >
          <el-option label="失败" :value="1">失败</el-option>
          <el-option label="成功" :value="0">成功</el-option>
        </el-select>
        <el-date-picker
          v-model="timeRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-right:10px;width:240px"
        >
        </el-date-picker>
        <el-button type="primary" @click="initData">搜索</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="custom-table">
        <el-table
          :data="dataList"
          style="width: 100%"
          @selection-change="
            select => {
              this.selectionList = select;
            }
          "
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="素材ID" prop="id"></el-table-column>
          <el-table-column
            label="上传者"
            prop="adminUserName"
          ></el-table-column>
          <el-table-column label="公众号" prop="channelName"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.isFailed === 0">成功</span>
              <span v-else>失败</span>
            </template>
          </el-table-column>
          <el-table-column label="失败原因">
            <template slot-scope="scope">
              <span v-if="'failReason' in scope.row">{{
                scope.row.failReason
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="上传时间" prop="createdTime">
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px; text-align: right"
          background
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="公众号"
      width="400px"
      append-to-body
      :before-close="handleCloseDialog"
      :visible.sync="showDialog"
    >
      <div class="container">
        <el-select
          v-model="selectChannelList"
          placeholder="请选择公众号"
          multiple
          filterable
          style="width:350px"
        >
          <el-option
            :label="item.channelName"
            :value="item.id"
            v-for="item in officialList"
            :key="item.id"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
      </div>
      <div class="button-list" style="margin-top:20px;text-align:center">
        <el-button type="primary" @click="handleOk" :loading="uploadLoading"
          >确定</el-button
        >
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import SelectPlatformChannel from '@/components/SelectPlatformChannel.vue'
import {
  getSourceDetailList,
  delSourceDetailList,
  uploadSourceDetail
} from '@/api/service.js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['show', 'resourceId', 'remark'],
  data () {
    return {
      params: {},
      loading: false,
      dataList: [],
      page: 1,
      pageSize: 10,
      sourceId: null, // 素材id
      accountChannelId: null,
      isFailed: null,
      timeRange: [], // 时间范围
      selectionList: [],
      total: 0,
      showDialog: false,
      selectChannelList: [],
      uploadLoading: false
    }
  },
  computed: {
    ...mapGetters(['officialList'])
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
        }
      }
    }
  },
  methods: {

    initData () {
      this.loading = true
      getSourceDetailList({
        page: this.page,
        pageSize: this.pageSize,
        sourceId: this.resourceId,
        isFailed: this.isFailed,
        accountChannelId:
          this.accountChannelId === null ? null : this.accountChannelId,
        startDate: this.timeRange[0] ? this.timeRange[0] : null,
        endDatae: this.timeRange[1] ? this.timeRange[1] : null
      })
        .then(res => {
          this.dataList = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleOk () {
      if (this.selectChannelList.length === 0) {
        this.$message({
          showClose: true,
          message: '请选择公众号!',
          type: 'error'
        })
        return
      }
      this.uploadLoading = true
      uploadSourceDetail({
        sourceId: this.resourceId,
        accountChannelIds: this.selectChannelList
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: '上传成功!',
            type: 'success'
          })
          this.showDialog = false
          this.selectChannelList = []
          this.initData()
        })
        .finally(() => {
          this.uploadLoading = false
        })
    },
    handleCancel () {
      this.showDialog = false
      this.selectChannelList = []
    },
    putChannel () {
      this.showDialog = true
    },
    refreshData () {
      this.initData()
    },
    delSelect () {
      const ids = this.selectionList.map(item => item.id).join(',')
      this.$confirm('此操作将永久删除所选素材, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delSourceDetailList(ids).then(() => {
            this.$message({
              showClose: true,
              message: '删除成功!',
              type: 'success'
            })
            this.initData()
          })
        })
        .catch(() => {})
    },
    handlePageChange (val) {
      this.page = val
      this.initData()
    },
    getTitle () {
      return `${this.remark}-`
    },
    handleCloseDialog () {
      this.$confirm('确定取消吗？, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.showDialog = false
        })
        .catch(() => {})
    },
    clearAccountChannelId () {
      this.accountChannelId = null
      this.initData()
    },
    handleReset () {
      this.params = {}
      this.accountChannelId = null
      this.timeRange = []
      this.isFailed = null
      this.initData()
    },
    beforeClose () {
      this.$emit('update:show', false)
    }
  },
  components: {
    SelectPlatformChannel
  }
}
</script>
<style lang="scss" scoped>
/deep/ #el-drawer__title {
  > div:nth-of-type(1) {

    margin: 0 auto;
    >div:nth-of-type(1){
      width: 200px;
    }
  }
  > :first-child {
    flex: none;
  }
}
.custom-table {
  margin-top: 20px;
}
</style>
