<template>
  <div
    class="resource-library box-shadow-light-grey padding-20 border-radius-6"
  >
    <div class="operation padding-bottom-20">
      <el-button @click="refreshData">刷新数据</el-button>
      <el-button class="" type="primary" @click="createSource"
        >新增素材</el-button
      >
      <el-input
        v-model="search"
        clearable
        placeholder="请输入备注信息"
        style="width: 240px; margin-right: 10px; margin-left: 10px"
        @clear="resetData('sourceName')"
      >
      </el-input>
      <el-select
        v-model="operationStaffName"
        placeholder="用户"
        style="margin-right: 10px; width: 150px"
      >
        <el-option
          v-for="item in operationStaff"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="timeRange"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right: 10px; width: 240px"
      >
      </el-date-picker>

      <el-button
        type="primary"
        @click="handleSearch"
        style="margin-right: 10px; margin-left: 0"
        class=""
        >搜索</el-button
      >
      <el-button type="info" style="margin-left: 0" @click="handleReset"
        >重置</el-button
      >
    </div>
    <div class="custom-table" v-loading="loading">
      <el-table
        :data="dataList"
        style="width: 100%"
        height="calc(100vh - 276px)"
        ref="dataTable"
        @selection-change="
          (select) => {
            this.selectionList = select;
          }
        "
      >
        <el-table-column
          label="素材Id"
          prop="id"
          min-width="50"
        ></el-table-column>
        <el-table-column
          label="上传者"
          prop="adminUserName"
          min-width="80"
        ></el-table-column>
        <el-table-column label="类型" min-width="50">
          <template slot-scope="scope">
            <span>{{ scope.row.type === 1 ? "音频" : "图片" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="素材" min-width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.type === 1">
              <audio
                class="thumbnail-audio"
                controls
                :src="scope.row.url"
              ></audio>
            </template>
            <template v-else>
              <el-popover placement="right" trigger="hover" title="原图">
                <div class="origin-img">
                  <img :src="scope.row.url" alt="原图" />
                </div>
                <div class="thumbnail-img" slot="reference">
                  <img :src="scope.row.url" />
                </div>
              </el-popover>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="备注" min-width="250">
          <template slot-scope="scope">
            <el-popover placement="right" title="备注" trigger="hover">
              <div>
                {{ scope.row.remark }}
              </div>
              <div class="line-overflow" slot="reference">
                {{ scope.row.remark }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="上传时间" prop="createdTime" min-width="150">
        </el-table-column>
        <el-table-column label="操作" min-width="200">
          <template slot-scope="scope">
            <el-button
              type="info"
              class="button-small"
              @click="showChannel(scope.row)"
              >公众号列表</el-button
            >
            <el-button
              type="primary"
              class="button-small"
              @click="editItem(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="button-small"
              @click="delResoure(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      direction="rtl"
      size="40%"
      title="新增素材"
      :visible.sync="showDrawer"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="上传素材" prop="url">
          <el-button type="primary" @click="uploadResource">上传素材</el-button>
          <span style="color: #ccc; margin-left: 10px"
            >音频支持mp3、m4a两种格式</span
          >
          <el-button
            type="danger"
            v-if="type === 1 && ruleForm.url"
            @click="ruleForm.url = null"
            >删除音频</el-button
          >
          <el-progress
            style="margin-top: 20px"
            :percentage="percentage"
            :show-text="false"
            v-if="showProcess"
          ></el-progress>
          <input
            type="file"
            style="display: none"
            ref="fileUpload"
            @change="handleFileChange"
          />

          <div class="resource" v-if="type === 2 && ruleForm.url">
            <template v-if="ruleForm.url">
              <img :src="ruleForm.url" alt="" />
              <div class="mask-hover">
                <i
                  class="iconfont icon-lajitong"
                  style="color: #fff"
                  @click="ruleForm.url = null"
                ></i>
              </div>
            </template>
          </div>
          <div class="resource" v-if="type === 1 && ruleForm.url">
            <template>
              <audio controls width="200" :src="ruleForm.url"></audio>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button type="info" @click="handleCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-dialog
      title="编辑素材"
      width="400px"
      :visible.sync="showDialog"
      :append-to-body="true"
      :before-close="() => (this.showDialog = false)"
    >
      <el-input
        type="textarea"
        v-model="remark"
        placeholder="请输入备注!"
      ></el-input>
      <div class="button-list" style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="edit">确定</el-button>
        <el-button type="info" @click="cancel">取消</el-button>
      </div>
    </el-dialog>
    <drawer-detail
      :show.sync="showDrawerChannel"
      :resourceId="resourceId"
      :remark="remark"
    ></drawer-detail>
  </div>
</template>
<script>
import SelectDeptUser from "@/components/SelectDeptUser";
import drawerDetail from "./resoure-detail.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  addSource,
  editSource,
  delSource,
  getSourceList,
} from "@/api/service.js";
import { uploadFile } from "@/api/common.js";
export default {
  name: "resourceLibrary",
  data() {
    return {
      loading: false,
      params: {
        deptId: null,
        adminUserId: null,
      },
      ruleForm: {
        type: null,
        url: null,
        remark: null,
      },
      showProcess: false,
      remark: null,
      resourceId: null,
      pageSize: 10,
      page: 1,
      total: 0,
      type: null,
      search: "",
      selectionList: "",
      isEdit: false,
      showDrawer: false,
      timeRange: [],
      dataList: [],
      showDialog: false,
      showDrawerChannel: false,
      operationStaffName: "",
      operationStaff: [],
      rules: {
        url: [
          {
            required: true,
            message: "请上传素材!",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userList"]),
    percentage() {
      return this.$store.state.uploadAudioPercent;
    },
  },
  watch: {
    percentage: {
      handler(newV) {
        this.showProcess = newV !== 100;
      },
    },
    // showDrawer:{
    //     handler(newV){

    //     }
    // }
  },
  mounted() {
    this.operationStaff = this.userList.filter(
      (item) => "isPut" in item && item.isPut === 0
    );
    this.initData();
  },
  methods: {
    ...mapMutations(["setUploadAudioPercent"]),
    initData() {
      this.loading = true;
      getSourceList({
        page: this.page,
        pageSize: this.pageSize,
        type: this.type,
        adminUserId: this.operationStaffName,
        remark: this.search,
        startDate: this.timeRange[0] ? this.timeRange[0] : null,
        endDate: this.timeRange[1] ? this.timeRange[1] : null,
      })
        .then((res) => {
          this.total = res.total;
          this.dataList = res.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadResource() {
      this.$refs.fileUpload.click();
    },
    refreshData() {
      this.initData();
    },
    createSource() {
      this.isEdit = false;
      this.showDrawer = true;
      this.showProcess = false;
    },

    handleSearch() {
      this.initData();
    },
    delSelectSource() {},
    handlePageChange(val) {
      this.page = val;
      this.initData();
    },
    editItem(val) {
      this.showDialog = true;
      this.resourceId = val.id;
      this.remark = val.remark;
    },
    handleFileChange(e) {
      const targetFile = e.target ? e.target.files[0] : e.srcElement.files[0];
      if (
        ["audio/mpeg", "audio/x-m4a", "audio/ogg"].includes(targetFile.type)
      ) {
        this.ruleForm.type = 1;
        uploadFile({
          file: targetFile,
        })
          .then((res) => {
            this.type = 1;
            this.$set(this.ruleForm, "type", 1);
            this.$set(this.ruleForm, "url", res);
          })
          .finally(() => {
            this.$refs.fileUpload.value = "";
          });
      } else {
        const fr = new FileReader();
        fr.readAsDataURL(targetFile);
        fr.onload = (event) => {
          const image = new Image();
          image.src = event.currentTarget.result;
          image.onload = () => {
            uploadFile({ file: targetFile })
              .then((res) => {
                this.$refs.fileUpload.value = "";
                this.type = 2;
                this.$set(this.ruleForm, "url", res);
                this.$set(this.ruleForm, "type", 2);
              })
              .finally(() => {
                this.$refs.fileUpload.value = "";
              });
          };
        };
      }
    },
    submitForm(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) {
          this.$message({
            showClose: true,
            message: "信息配置有误!",
            type: "error",
          });
        } else {
          addSource({
            type: this.ruleForm.type,
            // type: 2,
            url: this.ruleForm.url,
            remark: this.ruleForm.remark,
          }).then(() => {
            this.$message({
              showClose: true,
              message: "新增成功!",
              type: "success",
            });
            this.handleClose();
            this.initData();
          });
        }
      });
    },
    handleCancel() {
      this.handleClose();
    },
    delResoure(row) {
      this.$confirm("此操作将永久删除该素材,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSource(row.id).then(() => {
            this.$message({
              showClose: true,
              message: "删除成功!",
              type: "success",
            });
            this.initData();
          });
        })
        .catch(() => {});
    },
    edit() {
      editSource(this.resourceId, {
        remark: this.remark,
      }).then(() => {
        this.$message({
          showClose: true,
          message: "编辑成功!",
          type: "success",
        });
        this.showDialog = false;
        this.initData();
      });
    },
    showChannel(row) {
      this.showDrawerChannel = true;
      this.resourceId = row.id;
      this.remark = row.remark;
    },
    cancel() {
      this.showDialog = false;
      this.remark = null;
      this.resourceId = null;
    },
    handleClose() {
      this.showDrawer = false;
      this.showDrawerChannel = false;
      this.$refs.fileUpload.value = "";
      this.resetData();
    },
    handleReset() {
      this.resetData();
      this.initData();
    },
    resetData() {
      this.type = null;
      this.showProcess = false;
      this.remark = null;
      this.search = null;
      this.operationStaffName = null;
      this.timeRange = [];
      this.ruleForm = {
        type: null,
        url: null,
        remark: null,
      };
    },
  },
  components: {
    SelectDeptUser,
    drawerDetail,
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .el-drawer__body {
    box-sizing: border-box;
    padding: 20px;
  }
}
audio {
  width: 200px;
  height: 40px;
}
.resource-library {
  background: #fff;
  .operation {
    line-height: 40px;
  }
}
.origin-img {
  img {
    width: 400px;
  }
}
.thumbnail-img {
  width: 64px;
  height: 36px;
  img {
    width: 100%;
    height: 100%;
  }
}
.thumbnail-audio {
  width: 150px;
  height: 36px;
}

.resource {
  margin-top: 20px;
  position: relative;
  width: 200px;
  height: 160px;
  img {
     width:100%;
     height:100%;
  }
  audio{
      width:100%
  }
  .mask-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  &:hover {
    .mask-hover {
      display: block;
    }
  }
}
</style>
